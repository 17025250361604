<template>
  <b-upload
    v-model="file"
    :class="$style.upload"
    expanded
    :accept="accept"
    @input="input"
  >
    <b-field :class="$style.content">
      <b-input
        :value="fileName"
        :class="$style.input"
        :disabled="disabled"
        :placeholder="placeholder"
      />

      <b-button
        icon-left="upload"
        icon-pack="fal"
        type="is-light"
        :disabled="disabled"
        :class="$style.button"
      >
        Select File
      </b-button>
    </b-field>
  </b-upload>
</template>

<script>
export default {
  name: 'Upload',
  props: {
    accept: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    fileName() {
      return this.file?.name;
    },
  },
  methods: {
    input(file) {
      return this.$emit('input', file);
    },
  },
};
</script>

<style lang="scss" module>
.upload {
  position: relative;

  .content {
    flex-grow: 1;

    .input {
      flex-grow: 1;
    }
  }

  input[type='file'] {
    z-index: 999;
  }
}
</style>
