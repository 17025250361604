<template>
  <div
    :class="[
      $style.card,
      selected && $style['card--selected'],
      disabled && $style['card--disabled'],
    ]"
  >
    <font-awesome-icon
      v-if="selected"
      :icon="['fas', 'circle-check']"
      :class="$style.check"
    />

    <Icon
      :icon="type"
      :class="$style.icon"
    />

    <div :class="$style.name">
      {{ type.label }}
    </div>
  </div>
</template>

<script>
import Icon from './icon.vue';

export default {
  name: 'ProcessCard',
  components: {
    Icon,
  },
  props: {
    type: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.card {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 2px;
  padding: 20px 0;
  width: 104px;
  box-shadow: 0 2px 3px var(--color-gray), 0 0 0 1px var(--color-gray);
  transition-property: opacity, box-shadow;
  transition-duration: var(--transition-duration);
  cursor: pointer;

  .icon {
    margin-bottom: 15px;
    font-size: 65px;
  }

  .check {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 21px;
    color: var(--current-color);
  }

  &:hover {
    box-shadow: 0 2px 3px var(--color-gray-dark), 0 0 0 1px var(--color-gray-dark);
  }

  &--selected {
    box-shadow: 0 1px 3px var(--current-color), 0 0 0 1px var(--current-color);

    &:hover {
      box-shadow: 0 1px 3px var(--current-color), 0 0 0 1px var(--current-color);
    }
  }

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
</style>
