<template>
  <div
    :class="[
      $style.createProcess,
      showSelectFile && $style['createProcess--select'],
    ]"
  >
    <div :class="$style.name">
      <b-field
        label="Process name"
        :type="processValidationError && 'is-danger'"
        :message="processValidationError"
      >
        <b-input
          ref="name"
          v-model="processName"
          :disabled="loading"
          placeholder="Process name"
          @input="processValidationError = null"
          @keydown.native.enter="save"
        />
      </b-field>
    </div>

    <b-field
      v-if="!showSelectFile"
      label="Template type"
      :class="$style.types"
    >
      <div :class="$style.list">
        <Card
          v-for="type in processTypes"
          :key="type.label"
          :type="type"
          :selected="selectedType === type.label"
          :disabled="loading"
          @click.native="selectedType = type.label"
        />
      </div>
    </b-field>

    <b-field
      v-if="showSelectFile"
      label="Upload fillable PDF form"
      :type="templateValidationError && 'is-danger'"
      :message="templateValidationError"
      :class="$style.upload"
    >
      <Upload
        accept=".pdf"
        :disabled="loading"
        placeholder="Provide your PDF document here"
        @input="upload"
      />

      <b-field
        v-if="errorMessage"
        :class="$style.info"
      >
        <div :class="$style.error">
          {{ errorMessage }}
        </div>
      </b-field>

      <div :class="$style.help">
        Learn more about Fillable PDF templates in
        <a
          href="https://plumsail.com/docs/documents/v1.x/document-generation/fillable-pdf/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          the documentation
        </a>.
      </div>
    </b-field>

    <div :class="$style.controls">
      <b-button
        type="is-light"
        :disabled="loading"
        @click="cancel"
      >
        Cancel
      </b-button>

      <b-button
        type="is-primary"
        :loading="loading"
        @click="save"
      >
        Next
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useProcessesStore, useTemplatesStore, useHelpStore } from '@/stores/index.js';
import Upload from '@/views/documents/processes/components/upload.vue';
import Card from '@/views/documents/common/components/process-type/card.vue';
import processTypes from '@/views/documents/common/components/process-type/process-types.js';
import { locale, currentTimezone as timezone } from '@/utils/index.js';

export default {
  name: 'ProcessCreate',
  components: {
    Upload,
    Card,
  },
  data() {
    return {
      processName: null,
      processValidationError: null,
      templateValidationError: null,
      selectedType: 'DOCX',
      showSelectFile: false,
      loading: false,
      file: null,
      templateId: null,
      processTypes,
      errorMessage: null,
    };
  },
  watch: {
    processValidationError(value) {
      return value && this.$nextTick(() => this.$refs.name.focus());
    },
  },
  mounted() {
    this.$nextTick(() => this.setHelpOpen(true));
    this.$refs.name?.focus();
  },
  beforeDestroy() {
    this.setHelpOpen(false);
  },
  methods: {
    ...mapActions(useHelpStore, { setHelpOpen: 'setOpen' }),
    ...mapActions(useProcessesStore, ['readProcesses', 'createProcess', 'deleteProcess']),
    ...mapActions(useTemplatesStore, ['createTemplate', 'uploadTemplate', 'validateTemplate']),

    save() {
      if (this.showSelectFile && !this.file) return;
      if (this.templateValidationError) return;

      this.processValidationError = null;

      this.$nextTick(async () => {
        // For better user experience: if processValidationError exist focus name input
        if (!this.processName) return (this.processValidationError = 'Please, fill the process name');

        this.loading = true;

        // TODO: speak with @Roman how to solve this annoying logic
        // createProcess should throw error if process with current name already exist
        // but we create template before, and it's possible have unused templates
        const { data } = await this.readProcesses();

        this.loading = false;

        if (data.some(({ processName }) => processName === this.processName)) {
          return (this.processValidationError = 'Please, provide the unique process name');
        }

        if (this.processName.includes('<') || this.processName.includes('>')) {
          return (this.processValidationError = 'Name cannot contain: < >');
        }

        return this.create();
      });
    },
    async create() {
      if (!this.showSelectFile && this.selectedType === 'Fillable PDF') {
        return (this.showSelectFile = true);
      }

      this.loading = true;

      if (this.selectedType !== 'Fillable PDF') {
        const { data: templateId } = await this.createTemplate(
          {
            name: this.processName,
            type: this.selectedType,
          },
        );

        this.templateId = templateId;
      }

      const { data, error: errorData } = await this.createProcess(
        {
          processName: this.processName,
          templateId: this.templateId,
          locale,
          timezone,
        },
      );

      if (errorData?.error?.message) {
        this.errorMessage = errorData.error.message;
        this.loading = false;
        return;
      }

      return this.$router.push({ name: 'DocumentsProcessEdit', params: { processId: data.id } });
    },
    async upload(file) {
      this.loading = true;
      this.errorMessage = null;

      this.file = file;

      const { data: id } = await this.uploadTemplate({ file });

      this.templateId = id;

      // TODO: ask @Roman why we need this validateTemplate if uploadTemplate
      // can throw error if template not valid
      const { data: isValid } = await this.validateTemplate({ id });

      if (!isValid) {
        this.templateValidationError = 'Please upload valid template file';
      } else {
        this.templateValidationError = null;
      }
      this.loading = false;
    },
    cancel() {
      return this.$router.push({ name: 'DocumentsProcessesList' });
    },
  },
};
</script>

<style lang="scss" module>
.createProcess {
  align-self: start;

  .controls {
    display: flex;
    gap: 8px;
  }

  .name {
    margin-bottom: 15px;
  }

  .types {
    margin-bottom: 35px;

    .list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .upload {
    margin-bottom: 35px;
  }

  .help {
    margin-top: 5px;
    font-size: 12px;
  }

  &--select {
    min-width: 50%;
  }

  .info {
    display: block;
    font-size: 14px;
    line-height: 22px;

    .error {
      color: var(--color-danger);
    }
  }
}
</style>
